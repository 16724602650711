<template>
  <div class="view view--company view--company-about">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
            <h1 class="tit fw-500 fc-white ts-36 ts-md-48 mb-4">{{cc.s1.tit[1]}} <span class="txt fw-400 fc-white ts-20 ts-md-22">{{cc.s1.tit[2]}}</span></h1>
            <p class="txt fw-400 fc-silver mb-3" v-html="cc.s1.txt[0]" />
            <p class="txt fw-400 fc-silver mb-4" v-html="cc.s1.txt[1]" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-lg-8">
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-3" v-html="cc.s2.tit" />
            <p class="txt fw-400 fc-dark" v-html="cc.s2.txt" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-0 mb-3 mb-lg-0"
               v-for="(card, c) in cc.s2.cards" :key="`card-item-${c}`">
            <comp-card comp="t6" :data="card" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-0 mb-3 mb-lg-0"
               v-for="(card, c) in cc.s3.cards" :key="`card-item-${c}`">
            <div class="block block--terms">
              <p class="txt fw-500 fc-dark ts-18 mb-2" v-html="card.tit"/>
              <p class="txt fc-dark ts-14" v-html="card.txt" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--platform-alleviate">
              <div class="row">
                <div class="col-12 col-lg-8">
                  <h2 class="tit fw-500 fc-white ts-32 mb-4" v-html="alleviate.tit" />
                  <p class="txt fw-400 fc-white ts-18" v-html="alleviate.txt" />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="alleviate.btn" :theme="{type: 3, size: 'md', width: 174}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompCard from '@/components/Card'
import Btn from '@/components/Btn'
export default {
  components: {
    CompCard, Btn
  },
  computed: {
    cc () {
      return this.$t('views.company.about')
    },
    alleviate () {
      return this.$t('components.alleviate')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.company.about')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
