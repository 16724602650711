<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="(item, i) in cc" :key="`terms-item-${i}`">
        <div class="block block--terms" :id="`term-${item.key}`">
          <p class="txt fw-500 fc-dark ts-18 mb-2" v-html="item.tit" />
          <p class="txt fc-dark ts-14">
            <span v-for="(txt, t) in item.txt" :key="`terms-items-txt-${t}`">
              <span v-if="txt.type === 'txt'" v-html="txt.content" />
              <span v-if="txt.type === 'scroll'" v-html="txt.content" @click.prevent="goToTerm(txt.target)" style="text-decoration: underline; cursor: pointer" class="src" />
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cc () {
      return this.$t('components.terms')
    }
  },
  methods: {
    goToTerm (id) {
      const el = document.getElementById(`term-${id}`)
      // const wh = window.screen.height
      // const parent = el.offsetParent
      // const offset = el.offsetTop + parent.offsetTop
      // window.scrollTo({ top: offset, behavior: 'smooth' })
      el.scrollIntoView({ block: 'center', behavior: 'smooth' })
      setTimeout(() => {
        el.classList.add('active')
        setTimeout(() => { el.classList.remove('active') }, 800)
      }, 400)
      // console.log(offset)
    }
  }
}
</script>
