<template>
  <component :is="comp" class="btn" :class="cls" :style="style">
    <template v-slot:l v-if="icon && icon.dir === 'l'">
      <i>
        <img :src="`/img/icons/ui/ico-${icon.src}.svg`" alt=""/>
      </i>
    </template>
    <template v-slot:r v-if="icon && icon.dir === 'r'">
      <i>
        <img :src="`/img/icons/ui/ico-${icon.src}.svg`" alt=""/>
      </i>
    </template>
  </component>
</template>
<style lang="scss">
  @import '../assets/styles/components/btn';
</style>
<script>
import bExt from '@/components/btn/BtnExternal'
import bInt from '@/components/btn/BtnInternal'
export default {
  components: {
    bExt, bInt
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    theme: {
      required: false,
      type: Object
    }
  },
  computed: {
    comp () {
      const name = {
        external: 'bExt',
        internal: 'bInt'
      }
      return name[this.data.type]
    },
    cls () {
      const theme = this.theme ? this.theme : {}
      const t = theme.type ? theme.type : 1
      const s = theme.size ? theme.size : 'lg'
      const c = theme.color ? theme.color : 'blue'
      const w = isNaN(theme.width) ? theme.width : null
      const res = [`btn-t${t}`, `btn-${s}`, w ? `btn-${w}` : w, `btn-${c}`]
      return res
    },
    style () {
      const w = this.theme ? this.theme.width : null
      const res = !isNaN(w) ? `max-width: ${w}px` : null
      return res
    },
    icon () {
      return this.theme.icon ? this.theme.icon : null
    }
  }
}
</script>
