<template>
  <div class="view view--tools view--tools-videos">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="wrapper wrapper--v-center">
              <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-4" v-html="cc.s1.txt" />
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="img img--tools-tutorials">
              <img src="/img/views/img-tools-tutorials.svg" alt="tutorials" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--dark">
      <div class="container">
        <div class="row d-none">
          <div class="col-12">
            <comp-videos />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <comp-start />
    </section>
    <comp-modal comp="m1" :data="videoKey"/>
  </div>
</template>
<script>
import CompVideos from '@/components/Videos'
import CompStart from '@/components/Start'
import CompModal from '@/components/Modal'
export default {
  data: () => ({
    videoKey: 'Fc9_AXKdjpI',
    videoKeys: {
      en: ['NuADdH4-3SQ', 'YsHOFcZXCWA', 'swN_8dKs9oA']
    }
  }),
  components: {
    CompVideos, CompStart, CompModal
  },
  computed: {
    cc () {
      return this.$t('views.tools.videos')
    }
  },
  methods: {
    toggleVideo (i) {
      const keys = this.videoKeys[this.$i18n.locale]
      this.videoKey = keys[i]
      this.$store.state.isModalActive = true
    }
  },
  metaInfo () {
    const meta = this.$t('meta.tools.videos')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
