import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ww: window.innerWidth,
    isLoadingStart: false,
    isLoadingFinish: false,
    isNavToggle: false,
    isModalActive: false,
    isCookies: false,
    urlParams: '',
    cryptoListRow: {
      btc: 'Bitcoin',
      eth: 'Ethereum',
      ada: 'Cardano',
      mana: 'Decentraland',
      usdt: 'USDT'
    },
    cryptoList: {
      btc: 'Bitcoin',
      eth: 'Ethereum',
      ada: 'Cardano',
      mana: 'Decentraland',
      usdt: 'USDT',
      xmr: 'xmr',
      uni: 'uni',
      trx: 'trx',
      theta: 'theta',
      solana: 'solana',
      rep: 'rep',
      oxt: 'oxt',
      omg: 'omg',
      neo: 'neo',
      matic: 'matic',
      luna: 'luna',
      ltc: 'ltc',
      lrc: 'lrc',
      link: 'link',
      fantom: 'fantom',
      kusama: 'kusama',
      hot: 'hot',
      fil: 'fil',
      audius: 'audius',
      bnb: 'bnb',
      bat: 'bat',
      atom: 'atom',
      bch: 'bch',
      eos: 'eos',
      dot: 'dot',
      dash: 'dash',
      doge: 'doge',
      comp: 'comp',
      xrp: 'xrp',
      etc: 'etc'
    },
    cryptoAssetData: null,
    cryptoHistoryData: null,
    articlesData: null
  },
  mutations: {
    tglNav (state) {
      if (state.ww < 1200) state.isNavToggle = !state.isNavToggle
    },
    changeWW (state) {
      state.ww = window.innerWidth
    },
    setCookies (state) {
      const isCookies = window.localStorage.getItem('isCookies') || false
      state.isCookies = isCookies === 'true'
    },
    getUrlParams (state) {
      const url = window.location.href
      const params = url.split('?')
      if (params.length > 1) state.urlParams = `?${params[1]}`
      console.log(state.urlParams)
    },
    getCeyptoAssetData (state) {
      const api = new Promise((resolve, reject) => {
        fetch('/vendor/methods/getAssetData.php', state.fetchOpt)
          .then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      console.log(api)
      api.then(v => {
        const tmpObj = {}
        v.forEach(item => {
          if ('asset_id' in item) {
            const key = item.asset_id.toLowerCase()
            if (key !== 'bsc') {
              const price = +(item.price_usd.toFixed(2))
              const volume = item.volume_1day_usd
              tmpObj[key] = { price, volume }
            }
          }
        })
        state.cryptoAssetData = tmpObj
      })
    },
    // getCryptoHistoryData (state) {
    //   const keys = Object.keys(state.cryptoList).join()
    //   const api = new Promise((resolve, reject) => {
    //     fetch(`/vendor/methods/getAssetsHistory.php?assets=${keys}`, state.fetchOpt)
    //       .then((response) => { if (response.ok) return response.json() })
    //       .then((json) => { resolve(json) })
    //       .catch((error) => { reject(error) })
    //   })
    //   api.then(v => {
    //     state.cryptoHistoryData = v
    //   })
    // },
    getCryptoHistoryData (state) {
      const keys = Object.keys(state.cryptoList).join()
      return fetch(`/vendor/methods/getAssetsHistory.php?assets=${keys}`, state.fetchOpt)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`)
          }
          return response.json()
        })
        .then((json) => {
          state.cryptoHistoryData = json
        })
        .catch((error) => {
          // console.log("Failed to fetch crypto history data:", error)
          // Handle the error appropriately, e.g., setting an error state
          state.cryptoHistoryError = error.message
        })
    },
    getArticlesList (state) {
      const api = new Promise((resolve, reject) => {
        fetch('/vendor/methods/article2/getList.php', {
          method: 'POST'
        }).then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      api.then(data => {
        state.articlesData = data
        console.log(data)
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
