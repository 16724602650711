<template>
  <div class="footer__routes">
    <div class="row">
      <div class="col-6 col-md-3 mb-4"
           v-for="(item, i) in cc" :key="`footer-routes-col-${i}`">
        <h6 class="tit fw-400 ts-16 ts-md-18 fc-dark">{{ item.tit }}</h6>
        <div class="footer__routes-items" v-for="(link, l) in item.links" :key="`footer-routes-link-${i}-${l}`">
          <router-link :to="url(link.key)" class="src fw-400 ts-12 ts-sm-14">
            {{ link.txt }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cc () {
      return this.$t('footer.routes')
    },
    routes () {
      return this.$t('routes.internal')
    }
  },
  methods: {
    url (key) {
      return `/${this.$i18n.locale}/${this.routes[key]}`
    }
  }
}
</script>
