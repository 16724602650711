<template>
  <div class="view view--tools view--tools-news">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="wrapper wrapper--v-center">
              <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-4" v-html="cc.s1.txt" />
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="img img--tools-news">
              <img src="/img/views/img-tools-news.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row mb-5" v-if="articlePrimary">
          <div class="col-12">
            <div class="article article--main">
              <div class="article__content">
                <p class="txt fc-green ts-16 fw-500 mb-4">{{ articlePrimary.date }}</p>
                <h1 class="tit ts-34 ts-md-42 fw-600 mb-4">{{ decode64(articlePrimary.tit) }}</h1>
                <p class="txt fc-gray ts-14 ts-md-18 fw-300 mb-4">{{ decode64(articlePrimary.txt) }}</p>
                <router-link :to="`market-news/${articlePrimary.articleKey}`" class="btn btn-t1 btn-md btn-fc">
                  <span>{{cc.s1.btn}}</span>
                </router-link>
              </div>
              <div class="article__img">
                <img :src="`/articles/${articlePrimary.articleKey}/${decode64(articlePrimary.imgPrimary)}`" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5" v-else>
          <div class="col-12">
            <div class="article article--main">
              <div class="article__loading"></div>
            </div>
          </div>
        </div>
        <div class="row" v-if="articleHistory">
          <div class="col-12 col-md-4" v-for="(item, key) in articleHistory" :key="key">
            <div class="article article--prev">
              <div class="article__img">
                <img :src="`/articles/${key}/${decode64(item.imgPrimary)}`" alt="" />
              </div>
              <div class="article__content">
                <div>
                  <p class="txt fc-green ts-14 fw-400 mb-3 mb-md-2">{{ item.date }}</p>
                  <h2 class="tit ts-32 fw-600 mb-3">{{ decode64(item.tit) }}</h2>
                </div>
                <router-link :to="`market-news/${item.articleKey}`" class="btn btn-t5 btn-sm btn-fc">
                  <span>{{cc.s1.btn}}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12 col-md-4">
            <div class="article article--prev">
              <div class="article__loading"></div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="article article--prev">
              <div class="article__loading"></div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="article article--prev">
              <div class="article__loading"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
  @import '../../assets/styles/components/articles';
</style>
<script>
export default {
  computed: {
    cc () {
      return this.$t('views.tools.news')
    },
    articlesList () {
      console.log(this.$store.state.articlesData)
      return this.$store.state.articlesData ? this.$store.state.articlesData[this.$i18n.locale] : null
    },
    articlePrimary () {
      return this.articlesList ? this.articlesList[Object.keys(this.articlesList)[Object.keys(this.articlesList).length - 1]] : null
    },
    articleHistory () {
      const obj = this.articlesList
      let list = null
      if (obj) {
        list = {}
        const rev = Object.keys(obj).slice(0, -1).reverse()
        rev.forEach(item => {
          list[item] = obj[item]
        })
      }
      console.log(list)
      return list
    }
  },
  methods: {
    decode64 (item) {
      return decodeURIComponent(escape(window.atob(item)))
    }
  },
  metaInfo () {
    const meta = this.$t('meta.tools.news')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  beforeCreate () {
    if (!this.$store.state.articlesData) this.$store.commit('getArticlesList')
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
