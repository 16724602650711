<template>
  <div class="footer__application">
    <h6 class="tit fw-400 ts-16 ts-md-18 fc-dark">{{ cc.tit }}</h6>
    <div class="footer__application-wrapper">
      <btn-app platform="ios" />
      <btn-app platform="android" />
    </div>
  </div>
</template>
<script>
import btnApp from '@/components/BtnApp'
export default {
  components: {
    btnApp
  },
  computed: {
    cc () {
      return this.$t('footer.application')
    }
  }
}
</script>
