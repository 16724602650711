<template>
  <div class="card card--t6">
    <div class="card__wrapper">
      <div class="card__head">
        <i class="card__icon">
          <img :src="`/img/icons/cards/ico-card-${data.key}.svg`" alt="icon" />
        </i>
      </div>
      <div class="card__body">
        <h4 class="tit fw-500 fc-green fs-18 ta-center mb-4" v-html="data.tit"/>
        <p class="txt fc-dark ta-center" v-html="data.txt"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    }
  }
}
</script>
