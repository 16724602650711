<template>
    <div id="loader" class="loader" :class="[{'load-start': start}, {'load-finish': finish}]">
        {{ $store.state.isLoading }}
        <div class="loader__wrapper">
            <div class="loader__bar"></div>
            <div class="loader__icon">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
  @import '../assets/styles/components/loader';
</style>
<script>
export default {
  computed: {
    start () {
      return this.$store.state.isLoadingStart
    },
    finish () {
      return this.$store.state.isLoadingFinish
    }
  }
}
</script>
