<template>
  <div class="card card--t4">
    <div class="card__wrapper">
      <div class="card__head">
        <i class="card__icon">
          <img :src="`/img/icons/cards/ico-card-affiliate-${data.key}.svg`" alt="icon" />
        </i>
      </div>
      <div class="card__body">
        <h6 class="tit fw-500 fc-dark mb-2" v-html="data.tit" />
        <p class="txt fc-dark ts-14" v-html="data.txt" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    }
  }
}
</script>
