<template>
  <div class="view view--company view--company-faq">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
            <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 ts-md-48" v-html="cc.s1.tit[1]" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6" v-for="(item, i) in cc.s2.faq" :key="`faq-item-${i}`">
            <comp-faq :data="item" :sid="i" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--platform-alleviate">
              <div class="row">
                <div class="col-12 col-lg-8">
                  <h2 class="tit fw-500 fc-white ts-32 mb-4" v-html="alleviate.tit" />
                  <p class="txt fw-400 fc-white ts-18" v-html="alleviate.txt" />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="alleviate.btn" :theme="{type: 3, size: 'md', width: 174}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompFaq from '@/components/Faq'
import Btn from '@/components/Btn'
export default {
  components: {
    CompFaq, Btn
  },
  computed: {
    cc () {
      return this.$t('views.company.faq')
    },
    alleviate () {
      return this.$t('components.alleviate')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.company.faq')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
