<template>
  <div class="view view--platform view--platform-affiliate">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="wrapper wrapper--v-center">
              <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-4" v-html="cc.s1.txt" />
              <btn :data="cc.s1.btn" :theme="{type: 1, size: 'md', width: 'fc'}"/>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <comp-img type="parallax3"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-lg-6">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s2.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-3232 mb-4" v-html="cc.s2.tit[1]" />
            <p class="txt fc-dark ts-18" v-html="cc.s2.txt" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-4 mb-lg-0">
            <div class="block block--platform-program">
              <div class="row">
                <div class="col-12 mb-4">
                  <p class="txt fw-500 fc-dark ts-20 ta-center ta-lg-left" v-html="cc.s2.affiliate.tit" />
                </div>
              </div>
              <div class="row">
                <div class="col-12" :class="cc.s2.affiliate.cards.length !== d + 1 ? 'mb-4' : 'mb-0'"
                     v-for="(data, d) in cc.s2.affiliate.cards" :key="`card-item-${d}`">
                  <comp-card comp="t4" :data="data" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="block block--platform-program">
              <div class="row">
                <div class="col-12 mb-4">
                  <p class="txt fw-500 fc-dark ts-20 ta-center ta-lg-left" v-html="cc.s2.referred.tit" />
                </div>
                <div class="col-12" :class="cc.s2.referred.cards.length !== d + 1 ? 'mb-4' : 'mb-0'"
                     v-for="(data, d) in cc.s2.referred.cards" :key="`card-item-${d}`">
                  <comp-card comp="t4" :data="data" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 pt-lg-3">
          <div class="col-12">
            <div class="img img--platform-affiliate">
              <img src="/img/views/img-platform-affiliate-s2.png" alt="platform-affiliate">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row mb-4">
          <div class="col-12">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 ta-center ta-lg-left" v-html="cc.s3.tit" />
          </div>
        </div>
        <div class="row mb-4 mb-md-5">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3 mb-lg-0"
               v-for="(card, c) in cc.s3.cards" :key="`card-item-${c}`">
            <comp-card comp="t3" :data="card" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <btn :data="cc.s3.btn" :theme="{type: 1, size: 'md', width: 'fc'}" class="mx-auto" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
import CompImg from '@/components/Img'
import CompCard from '@/components/Card'
export default {
  components: {
    Btn, CompImg, CompCard
  },
  computed: {
    cc () {
      return this.$t('views.partners.affiliate')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.partners.affiliate')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
