<template>
  <div class="policy">
    <div class="policy__item" v-for="(item, i) in data" :key="`policy-item-${i}`">
      <p class="txt mb-2" v-if="isInObj('label', item)">
        <span class="txt fw-600 ts-14" v-html="`${pid}.${i + 1}. `" />
        <span class="txt fw-400 ts-14 fc-gray" v-html="item.label" />
      </p>
      <p class="txt fw-400 ts-14 fc-gray mb-2" v-if="isInObj('txt', item)" v-html="item.txt" />
      <template v-if="isInObj('numbered', item)">
        <div class="mb-2" v-for="(numbered, n) in item.numbered" :key="`policy-item-numbered-${n}`">
          <p class="txt fw-400 ts-14 fc-gray" v-html="`${n + 1}. ${numbered[0]}`" />
          <p class="txt fw-400 ts-14 fc-gray" v-html="numbered[1]" />
        </div>
      </template>
      <p class="txt mb-2" v-if="isInObj('term', item)">
        <span class="txt fw-500 ts-14 fc-dark">{{ item.term[0] }} </span>
        <span class="txt fw-400 ts-14 fc-gray">{{ item.term[1] }}</span>
      </p>
      <ul class="list list--policy" v-if="isInObj('list', item)">
        <li class="li" v-for="(list, l) in item.list" :key="`policy-list-item-${l}`">
          <p class="txt fw-400 ts-14 fc-gray mb-2">{{ list }}</p>
        </li>
      </ul>
      <template v-if="isInObj('sub', item)">
        <comp-policy :data="item.sub" :pid="`${pid}.${i + 1}`" />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      required: true,
      type: Array
    },
    pid: {
      required: false,
      default: '1'
    }
  },
  components: {
    CompPolicy: () => import('@/components/Policy')
  },
  methods: {
    isInObj (key, obj) {
      return key in obj
    }
  }
}
</script>
