<template>
  <main class="main" id="app">
    <comp-header/>
    <router-view/>
    <comp-footer/>
    <comp-modal comp="m2"/>
    <comp-loader/>
  </main>
</template>
<style lang="scss">
  @import '~bootstrap/scss/bootstrap-grid';
  @import 'assets/styles/routes/origin';
</style>
<script>
import compHeader from '@/components/Header'
import compFooter from '@/components/Footer'
import compLoader from '@/components/Loader'
import compModal from '@/components/Modal'
export default {
  components: {
    compHeader, compFooter, compModal, compLoader
  },
  metaInfo () {
    const meta = this.$t('meta.home')
    return {
      title: 'Coinfy',
      meta: [
        { property: 'og:title', content: 'Coinfy.io' },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  beforeCreate () {
    this.$store.commit('setCookies')
    this.$store.commit('getUrlParams')
  },
  created () {
    window.addEventListener('resize', () => { this.$store.commit('changeWW') })
    // if (this.$store.state.isCookies) document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
    // console.log(document.getElementsByTagName('body'))
  }
}
</script>
