<template>
  <div class="view view--tools view--tools-editor">
    <section class="section d-flex align-items-center" v-if="!isAuth">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4 offset-lg-4">
            <div class="form ">
              <div class="row">
                <div class="col-12 mb-3">
                  <input type="password" class="form__input" placeholder="Password" v-model="dPwd">
                </div>
                <div class="col-12">
                  <button class="btn btn-t5 btn-md me-3" @click.prevent="submitPwd">
                    <span>Proceed</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" v-if="isAuth && isHome">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="wrapper wrapper--v-center">
              <h2 class="tit fw-500 fc-dark ts-26 ts-md-32">Articles</h2>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <button class="btn btn-t2 btn-sm btn-fc ms-auto" @click.prevent="toggleEditor()">
                <span>Add new article</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="articles__list">
              <table class="articles__table">
                <thead class="articles__table-head">
                  <tr>
                    <th>
                      <div class="articles__table-col">
                        <p class="txt">ID</p>
                      </div>
                    </th>
                    <th>
                      <div class="articles__table-col">
                        <p class="txt">Name</p>
                      </div>
                    </th>
                    <th>
                      <div class="articles__table-col">
                        <p class="txt">Language</p>
                      </div>
                    </th>
                    <th>
                      <div class="articles__table-col">
                        <p class="txt">Date</p>
                      </div>
                    </th>
                    <th>
                      <div class="articles__table-col">
                        <p class="txt">Action</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="articles__table-body">
                  <tr  v-for="(item, i) in articlesListGroup" :key="i">
                    <td>
                      <div class="articles__table-col">
                        <p class="txt">{{ item.articleKey }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="articles__table-col">
                        <p class="txt">{{ decode64(item.tit) }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="articles__table-col">
                        <p class="txt">{{ item.lang }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="articles__table-col">
                        <p class="txt">{{ item.date }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="articles__table-col">
                        <div class="d-flex">
                          <button class="btn btn-t1 btn-editor btn-fc me-2" @click.prevent="editArticle(item.lang, item.articleKey)">
                            <i class="ico ico--edit">
                              <img src="/img/icons/ui/ico-edit.svg" alt="Edit" />
                            </i>
                          </button>
                          <button class="btn btn-t6 btn-editor btn-fc" @click.prevent="toggleModalDelete(item.articleKey, item.lang)">
                            <i class="ico ico--delete">
                              <img src="/img/icons/ui/ico-delete.svg" alt="Delete" />
                            </i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" v-if="isAuth && isEditor">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12 col-lg-6">
            <div class="wrapper wrapper--v-center">
              <h2 class="tit fw-500 fc-dark ts-26 ts-md-32">
                Main article settings
              </h2>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <button class="btn btn-t2 btn-sm btn-fc ms-auto" @click.prevent="toggleEditor()">
                <span>Back to articles list</span>
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <div class="article__switcher" :class="{'active': !isOldArticle}">
              <span @click.prevent="isOldArticle = true">Preview as main article</span>
              <span @click.prevent="isOldArticle = false">Preview as old article</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-flex justify-content-end">
              <div class="article__switcher" :class="{'active': lang === 'cs'}">
                <span @click.prevent="lang = 'en'" style="width: 50px">EN</span>
                <span @click.prevent="lang = 'cs'" style="width: 50px">CS</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none">
          <input type="file" accept="image/png" id="data-main-image" @change="e => onImageChange(e, 'primary')" />
          <input type="file" accept="image/png" id="data-inner-image" @change="e => onImageChange(e, 'secondary')" />
        </div>
        <div class="row" :class="{'d-none': !isOldArticle}">
          <div class="col-12">
            <div class="article article--main">
              <div class="article__content">
                <p class="txt fc-green ts-16 fw-500 mb-4">{{ today }}</p>
                <h1 class="tit ts-34 ts-md-42 fw-600 mb-4" contentEditable="true" @input="e => onInput(e, 'tit')">{{ articleDynamicData.tit }}</h1>
                <p class="txt fc-gray ts-14 ts-md-18 fw-300 mb-4" contentEditable="true" @input="e => onInput(e, 'txt')">{{ articleDynamicData.txt }}</p>
                <a class="btn btn-t1 btn-md btn-fc">
                  <span>Read article</span>
                </a>
              </div>
              <div class="article__img">
                <img :src="articleDynamicData.images.primary" alt=" " />
                <!-- @click.prevent="e => uploadImg(e, 'main')" -->
                <label for="data-main-image">
                  <i class="ico">
                    <img src="/img/components/articles/ico-upload-image.svg" alt="+" />
                  </i>
                  <span>Upload image</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" :class="{'d-none': isOldArticle}">
          <div class="col-4">
            <div class="article article--prev">
              <div class="article__img">
                <img :src="articleDynamicData.images.primary" alt=" " />
                <!-- <label @click.prevent="e => uploadImg(e, 'main')"> -->
                <label for="data-main-image">
                  <i class="ico">
                    <img src="/img/components/articles/ico-upload-image.svg" alt="+" />
                  </i>
                  <span>Upload image</span>
                </label>
              </div>
              <div class="article__content">
                <div>
                  <p class="txt fc-green ts-14 fw-400 mb-3 mb-md-2">{{ today }}</p>
                  <h2 class="tit ts-32 fw-600 mb-3" contentEditable="true" @input="e => onInput(e, 'tit')">{{ articleDynamicData.tit }}</h2>
                </div>
                <a class="btn btn-t5 btn-sm btn-fc">
                  <span>Read article</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="article article--prev">
              <div class="article__img">
                <img src="/img/components/articles/tmp.png" />
              </div>
              <div class="article__content">
                <div>
                  <p class="txt fc-green ts-14 fw-400 mb-3 mb-md-2">01.01.2022</p>
                  <h2 class="tit ts-32 fw-600 mb-3">Lorem ipsum, dolor amet consectetur elit lorem! Dolorum doloribus veniam</h2>
                </div>
                <a class="btn btn-t5 btn-sm btn-fc">
                  <span>Read article</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="article article--prev">
              <div class="article__img">
                <img src="/img/components/articles/tmp.png" />
              </div>
              <div class="article__content">
                <div>
                  <p class="txt fc-green ts-14 fw-400 mb-3 mb-md-2">01.01.2022</p>
                  <h2 class="tit ts-32 fw-600 mb-3">Lorem ipsum, dolor amet consectetur elit lorem!</h2>
                </div>
                <a class="btn btn-t5 btn-sm btn-fc">
                  <span>Read article</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section mt-5" v-if="isAuth && isEditor">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12">
            <div class="wrapper wrapper--v-center">
              <h2 class="tit fw-500 fc-dark ts-26 ts-md-32">
                Article content
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="article article--inner">
              <div class="article__content">
                <p class="txt fc-green ts-16 fw-500 mb-4">{{ today }}</p>
                <h1 class="tit ts-34 ts-md-42 fw-600 fc-white mb-4">{{ articleDynamicData.tit }}</h1>
                <p class="txt ts-14 ts-md-18 fw-300 fc-white">{{ articleDynamicData.txt }}</p>
              </div>
              <div class="article__img">
                <img :src="articleDynamicData.images.secondary" alt="" />
                <label for="data-inner-image">
                  <span>
                    <i class="ico">
                      <img src="/img/components/articles/ico-upload-image.svg" alt="+" />
                    </i>
                    <span>Upload image</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-8 offset-2">
            <div class="article article--editor">
              <vue-editor v-model="articleDynamicData.content" :editor-toolbar="customToolbar" useCustomImageHandler @image-added="handleImageAdded" />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <div class="article__switcher mx-auto" :class="{'active': lang === 'cs'}">
              <span @click.prevent="lang = 'en'" style="width: 50px">EN</span>
              <span @click.prevent="lang = 'cs'" style="width: 50px">CS</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-t5 btn-lg btn-fc mx-auto" @click.prevent="saveArticle()">
              <span>Save article</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <div class="modal modal--delete" :class="{'active': isModalDelActive}">
      <div class="modal__close-area" @click.prevent="closeModal()"></div>
      <div class="modal__wrapper">
        <div class="modal__close-btn" @click.prevent="closeModal()"></div>
        <p class="txt ts-18 fw-400 ta-center mb-3">Are you sure you want to delete article?</p>
        <div v-if="delTarget.lang && delTarget.key">
          <p class="txt ts-16 mb-1">Article id: {{ articlesList[delTarget.lang][delTarget.key].articleKey }}</p>
          <p class="txt ts-16 mb-1">Article name: {{ decode64(articlesList[delTarget.lang][delTarget.key].tit) }}</p>
          <p class="txt ts-16 mb-1">Article language: {{ articlesList[delTarget.lang][delTarget.key].lang }}</p>
          <p class="txt ts-16 mb-1">Article date: {{ articlesList[delTarget.lang][delTarget.key].date }}</p>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3">
          <button class="btn btn-t6 btn-md me-3" @click.prevent="deleteArticle(delTarget.key, delTarget.lang)">
            <span>Delete</span>
          </button>
          <button class="btn btn-t5 btn-md" @click.prevent="closeModal()">
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal modal--working" :class="{'active': isModalWorkingActive}">
      <div class="modal__close-area"></div>
      <div class="modal__wrapper">
        <div v-if="isWorking">
          <div class="loader__icon mx-auto"><span></span><span></span><span></span></div>
        </div>
        <div v-if="isWorkSuccess">
          <p class="txt ts-18 fw-400 ta-center">The article was successfully saved!</p>
        </div>
        <div v-if="isWorkError">
          <p class="txt ts-18 fw-400 ta-center mb-3">There is an error with saving the article.</p>
          <p class="txt ts-16 fw-400 ta-center mb-3">Please contact developer or try again later.</p>
          <p class="txt ts-14 fw-400 ta-center mb-3">Error reason: {{ errorReason }}</p>
          <button class="btn btn-t5 btn-md" @click.prevent="closeModal('working')">
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import '../../assets/styles/components/form';
  @import '../../assets/styles/components/articles';
  @import '../../assets/styles/components/modal';
</style>
<script>
// import { defineComponent } from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },
  data: () => ({
    isAuth: false,
    isHome: false,
    isEditor: false,
    isEdit: false,
    isModalDelActive: false,
    isModalWorkingActive: false,
    isWorking: false,
    isWorkSuccess: false,
    isWorkError: false,
    errorReason: null,
    delTarget: {
      key: null,
      lang: null
    },
    dPwd: '',
    uPwd64: 'RjMwWFJieTc2Mlh2NHFaMg==', // F30XRby762Xv4qZ2
    isOldArticle: true,
    lang: 'en',
    articleDynamicData: {
      tit: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit',
      txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod ratione blanditiis recusandae quo aut fuga optio quae saepe autem ipsum beatae vero eos provident, quaerat cupiditate delectus deserunt consectetur!',
      content: '<h2>Lorem ipsum dolor</h2><p class="ql-align-justify">Voluptates, molestiae? Accusantium corporis pariatur, incidunt illum officia minima delectus nesciunt sed voluptatibus nobis odio ducimus accusamus vero id in magnam! Nostrum?</p></p>',
      images: {
        primary: '',
        secondary: '',
        other: []
      }
    },
    articleStaticData: {
      key: Date.now(),
      tit: '',
      txt: '',
      content: '',
      images: {
        primary: '',
        secondary: '',
        other: []
      }
    },
    customToolbar: [
      [{ header: [2, 3] }],
      ['bold', 'italic', 'underline'],
      [{ align: [false, 'center', 'right', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image']
    ]
  }),
  computed: {
    dPwd64 () {
      return btoa(this.dPwd)
    },
    today () {
      const date = new Date()
      const d = date.getDate()
      const m = ('0' + (date.getMonth() + 1)).slice(-2)
      const y = date.getFullYear()
      return `${d}.${m}.${y}`
    },
    articlesList () {
      return this.$store.state.articlesData ? this.$store.state.articlesData : null
    },
    articlesListGroup () {
      const locales = ['en', 'cs']
      const list = this.articlesList
      const group = {}
      if (list) {
        locales.forEach(item => {
          Object.keys(list[item]).reverse().forEach(val => {
            const key = `${val}-${item}`
            group[key] = list[item][val]
          })
        })
      }
      return group
    }
  },
  methods: {
    submitPwd () {
      this.isAuth = this.uPwd64 === this.dPwd64
      if (this.isAuth) this.isHome = true
    },
    onInput (e, key) {
      const txt = e.target.textContent
      this.articleDynamicData[key] = txt
      this.articleStaticData[key] = txt
      this.placeCaretAtEnd(e.target)
    },
    toggleEditor () {
      this.isHome = !this.isHome
      this.isEditor = !this.isEditor
      this.clearEditor()
    },
    editArticle (lang, key) {
      this.toggleEditor()
      const data = this.articlesList[lang][key]
      this.articleDynamicData = {
        tit: this.decode64(data.tit),
        txt: this.decode64(data.txt),
        content: this.decode64(data.content),
        images: {
          primary: `/articles/${key}/${this.decode64(data.imgPrimary)}`,
          secondary: `/articles/${key}/${this.decode64(data.imgSecondary)}`,
          other: []
        }
      }
      this.articleStaticData.key = key
      this.isEdit = true
      this.lang = lang
    },
    clearEditor () {
      this.articleDynamicData = {
        tit: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit',
        txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod ratione blanditiis recusandae quo aut fuga optio quae saepe autem ipsum beatae vero eos provident, quaerat cupiditate delectus deserunt consectetur!',
        content: '<h2>Lorem ipsum dolor</h2><p class="ql-align-justify">Voluptates, molestiae? Accusantium corporis pariatur, incidunt illum officia minima delectus nesciunt sed voluptatibus nobis odio ducimus accusamus vero id in magnam! Nostrum?</p></p>',
        images: {
          primary: '',
          secondary: '',
          other: []
        }
      }
      this.isEdit = false
      this.lang = 'en'
    },
    onImageChange (e, key) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const file = files[0]
      const reader = new FileReader()
      reader.onload = (e) => { this.articleDynamicData.images[key] = e.target.result }
      reader.readAsDataURL(file)
      this.articleStaticData.images[key] = file
      console.log(this.articleStaticData)
    },
    placeCaretAtEnd (el) {
      el.focus()
      if (typeof window.getSelection !== 'undefined' &&
            typeof document.createRange !== 'undefined') {
        var range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        var sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      } else if (typeof document.body.createTextRange !== 'undefined') {
        var textRange = document.body.createTextRange()
        textRange.moveToElementText(el)
        textRange.collapse(false)
        textRange.select()
      }
    },
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      const reader = new FileReader()
      const vm = this
      reader.readAsDataURL(file)
      reader.onload = function () {
        const b64file = reader.result
        const uid = Date.now()
        Editor.insertEmbed(cursorLocation, 'image', b64file)
        Editor.formatText(cursorLocation, 1, 'alt', `/articles/${vm.articleStaticData.key}/${file.name}?+!${uid}`)
        const tmpObj = {}
        tmpObj[uid] = file
        vm.articleDynamicData.images.other.push(tmpObj)
        resetUploader()
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    toggleModalDelete (key, lang) {
      this.isDelModal = true
      this.delTarget = { key, lang }
      this.isModalDelActive = true
    },
    saveArticle () {
      if (!this.isWorking) {
        this.isWorking = true
        this.articleStaticData.images.other = []
        const dd = this.articleDynamicData
        this.articleStaticData.tit = dd.tit
        this.articleStaticData.txt = dd.txt
        const content = dd.content
        const dom = new DOMParser().parseFromString(content, 'text/html')
        const images = dom.getElementsByTagName('img')
        const imagesArr = []
        for (let i = 0; i < images.length; i++) {
          const alt = images[i].getAttribute('alt').toString()
          const src = alt.split('?+!')[0]
          const uid = alt.split('?+!')[1]
          // console.log(uid)
          images[i].setAttribute('src', src)
          images[i].setAttribute('alt', 'image')
          imagesArr.push(uid)
        }
        dd.images.other.forEach(item => {
          const key = Object.keys(item)[0]
          if (imagesArr.includes(key)) {
            this.articleStaticData.images.other.push(item[key])
          }
        })
        this.articleStaticData.content = dom.body.innerHTML
        this.sendToServer(this.articleStaticData)
      }
    },
    sendToServer (data) {
      this.isModalWorkingActive = true
      const key = data.key
      const date = this.today
      const lang = this.lang
      const tit = data.tit
      const txt = data.txt
      const content = data.content
      const imgPrimary = data.images.primary
      const imgSecondary = data.images.secondary
      const imgOther = data.images.other
      const imgOtherArr = []
      const formData = new FormData()
      formData.append('key', key)
      formData.append('date', date)
      formData.append('lang', lang)
      formData.append('tit', tit)
      formData.append('txt', txt)
      formData.append('content', content)
      formData.append('imgPrimary', imgPrimary)
      formData.append('imgSecondary', imgSecondary)
      imgOther.forEach((item, i) => {
        const name = `imgOther-${i}`
        formData.append(name, item)
        imgOtherArr.push(name)
      })
      formData.append('imgOtherArr', imgOtherArr)
      const method = this.isEdit ? '/vendor/methods/article2/edit.php' : '/vendor/methods/article2/save.php'
      fetch(method, {
        method: 'POST',
        body: formData
      }).then((resp) => {
        return resp.json()
      }).then((json) => {
        this.isWorking = false
        const status = json.status
        if (status === 'success') {
          this.isWorkSuccess = true
          this.isWorkError = false
          this.$store.commit('getArticlesList')
          setTimeout(() => {
            this.isModalWorkingActive = false
            this.isWorkSuccess = false
          }, 2000)
        } else {
          this.isWorkSuccess = false
          this.isWorkError = true
          this.errorReason = json.reason
        }
      })
    },
    deleteArticle (key, lang) {
      if (!this.isWorking) {
        this.isWorking = true
        const formData = new FormData()
        formData.append('key', key)
        formData.append('lang', lang)
        fetch('/vendor/methods/article2/delete.php', {
          method: 'POST',
          body: formData
        }).then((resp) => {
          return resp.json()
        }).then((json) => {
          console.log(json)
          this.isWorking = false
          this.closeModal()
          this.delTarget = {
            key: null,
            lang: null
          }
          this.$store.commit('getArticlesList')
        })
      }
    },
    decode64 (item) {
      return decodeURIComponent(escape(window.atob(item)))
    },
    closeModal (target = 'del') {
      if (target === 'del') this.isModalDelActive = false
      if (target === 'working') this.isModalWorkingActive = false
    }
  },
  watch: {
    articlesList () {
      console.log('Changed')
    }
  },
  beforeCreate () {
    if (!this.$store.state.articlesData) this.$store.commit('getArticlesList')
  }
}
</script>
