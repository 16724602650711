<template>
  <div class="row">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="video">
        <div class="video__item" v-for="(item, i) in videos" :key="`video-item-${i}`" @click.prevent="$parent.toggleVideo(i)">
          <div class="video__item-preview">
            <img :src="`/img/videos/img-${item.key}.png`" alt="img">
            <img src="/img/img-tmp-1.png" alt="img" />
          </div>
          <p class="txt fc-white ta-center" v-html="txt[i][locale]" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '../assets/styles/components/videos';
</style>
<script>
export default {
  data: () => ({
    txt: [
      {
        en: 'EN Title V1',
        cs: 'CS Title V1'
      },
      {
        en: 'EN Title V2',
        cs: 'CS Title V2'
      },
      {
        en: 'EN Title V3',
        cs: 'CS Title V3'
      }
    ]
  }),
  computed: {
    locale () {
      return this.$i18n.locale
    },
    videos () {
      return this.$parent.videoKeys[this.locale]
    }
  }
}
</script>
