<template>
  <div class="header__switcher">
    <div class="header__routes-item" :class="{'active': isNavToggle}">
      <div class="header__exp">
        <input type="checkbox" :id="isMob ? `exp-mob-locale` : `exp-desk-locale`" v-if="isMob" :checked="selExp" />
        <label class="header__exp-head" :for="isMob ? `exp-mob-locale` : `exp-desk-locale`" @click.prevent="selExp = !selExp">
          <span>{{ locales[locale] }}</span>
          <i class="header__exp-arrow"></i>
        </label>
        <div class="header__exp-body">
          <div class="header__exp-item" v-for="(locale, l) in locales" :key="`routes-item-locale-${l}`">
            <p class="header__exp-link" @click="changeLocale(l)">
              <span>{{ locale }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    selExp: false,
    locales: {
      en: 'English',
      vi: 'Tiếng Việt',
      cs: 'Čeština'
    }
  }),
  computed: {
    locale () {
      return this.$i18n.locale
    },
    isNavToggle () {
      return this.$store.state.isNavToggle
    },
    isMob () {
      return this.$store.state.ww < 1200
    }
  },
  methods: {
    changeLocale (l) {
      this.selExp = false
      if (this.$i18n.locale !== l) {
        this.$router.push({
          params: { lang: l }
        })
        this.$i18n.locale = l
      }
    }
  }
}
</script>
