<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-0 px-0 pe-lg-2 mb-sm-3 mb-lg-0">
        <div class="start start--one">
          <div class="start__wrapper">
            <p class="txt fw-300 ts-20 ts-lg-22 start__pre" v-html="cc[0].tit[0]" />
            <h3 class="tit fw-500 ts-26 ts-lg-32 mb-3" v-html="cc[0].tit[1]" />
            <p class="txt fw-200 ts-16 ts-lg-18" v-html="cc[0].txt" />
          </div>
          <div class="start__wrapper">
            <btn :data="cc[0].btn" :theme="{type: 3, size: 'md', width: 174}"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-0 px-0 ps-lg-2">
        <div class="start start--two">
          <div class="start__wrapper">
            <h3 class="tit fw-500 ts-26 ts-lg-32 mb-3" v-html="cc[1].tit" />
            <p class="txt fw-200 ts-16 ts-lg-18" v-html="cc[1].txt" />
          </div>
          <div class="start__wrapper">
            <btn :data="cc[1].btn" :theme="{type: 1, size: 'md', width: 174}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import "../assets/styles/components/Start";
</style>
<script>
import Btn from '@/components/Btn'
export default {
  components: {
    Btn
  },
  computed: {
    cc () {
      return this.$t('components.start')
    }
  }
}
</script>
