<template>
  <div class="view view--platform view--platform-dx">
    <section class="section">
      <div class="container">
        <div class="row mb-4 mb-md-5">
          <div class="col-12 col-md-7">
            <div class="wrapper wrapper--v-center">
              <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
              <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
              <p class="txt fw-400 fc-gray mb-4" v-html="cc.s1.txt" />
              <div class="d-none d-md-block">
                <btn :data="cc.s1.btn" :theme="{type: 1, size: 'md', width: 'fc'}"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 mb-4 mb-md-0">
            <div class="wrapper wrapper--v-center">
              <div class="img img--platform-dx">
                <img src="/img/views/img-platform-dxtrade-s1.png" alt="notebook" />
              </div>
            </div>
          </div>
          <div class="col-12 mb-4 mb-md-0 mt-md-4">
            <div class="d-flex flex-column flex-md-row">
              <ul class="list list--check list--check-green me-md-4 me-lg-5" v-for="(item, i) in cc.s1.list" :key="`list-item-${i}`">
                <li class="li mb-2 mb-md-0">
                  <span class="txt fw-500 fc-dark" v-html="item" />
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 d-md-none">
            <btn :data="cc.s1.btn" :theme="{type: 1, size: 'md', width: 'fc'}"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s2.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-4" v-html="cc.s2.tit[1]" />
            <p class="txt fc-dark ts-18 mb-4" v-html="cc.s2.txt" />
            <div class="d-none d-lg-block">
              <ul class="list list--check list--check-green mb-5">
                <li class="li" :class="cc.s2.list.length !== i + 1 ? 'mb-4' : ''" v-for="(item, i) in cc.s2.list" :key="`list-item-${i}`">
                  <span class="txt fw-500 fc-dark">{{ item }}</span>
                </li>
              </ul>
              <div class="d-flex justify-content-start align-items-center">
                <btn-app platform="ios" class="me-3" />
                <btn-app platform="android" />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <comp-img type="parallax4"/>
          </div>
          <div class="col-12 d-lg-none mt-5">
            <ul class="list list--check list--check-green mb-5">
              <li class="li" :class="cc.s2.list.length !== i + 1 ? 'mb-4' : ''" v-for="(item, i) in cc.s2.list" :key="`list-item-${i}`">
                <span class="txt fw-500 fc-dark">{{ item }}</span>
              </li>
            </ul>
            <div class="d-flex justify-content-start align-items-center">
              <btn-app platform="ios" class="me-3" />
              <btn-app platform="android" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--platform-dx">
              <div class="row mb-3 mb-md-5">
                <div class="col-12 col-lg-9">
                  <p class="txt fw-400 fc-white ts-20 ts-md-22 mb-3" v-html="cc.s3.tit[0]" />
                  <h2 class="tit fw-400 fc-green ts-26 ts-md-32 mb-4" v-html="cc.s3.tit[1]" />
                  <p class="txt fc-white ts-16 ts-md-18" v-html="cc.s3.txt" />
                </div>
                <div class="col-12 col-lg-3 d-none d-lg-block">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="cc.s3.btn" :theme="{type: 3, size: 'lg', width: 174}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-lg-10 mb-4 mb-md-0">
                  <div class="d-flex flex-column flex-md-row">
                    <ul class="list list--check list--check-green me-md-4" v-for="(item, i) in cc.s3.list" :key="`list-item-${i}`">
                      <li class="li mb-2 mb-md-4">
                        <span class="txt fw-500 fc-white">{{ item }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 d-lg-none">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="cc.s3.btn" :theme="{type: 3, size: 'lg', width: 174}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-lg-6">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s4.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-4" v-html="cc.s4.tit[1]" />
            <p class="txt fc-dark ts-18" v-html="cc.s4.txt" />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-lg-6" v-for="(items, i) in features" :key="`feature-item-${i}`">
            <comp-card comp="t5" :data="card" v-for="(card, c) in items" :key="`feature-card-${c}`" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <btn :data="cc.s4.btn" :theme="{type: 1, size: 'md', width: 174}" class="mx-auto" />
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 ta-center mb-3" v-html="cc.s5.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 ta-center mb-4" v-html="cc.s5.tit[1]" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <comp-crypto comp="tbl" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--platform-alleviate">
              <div class="row">
                <div class="col-12 col-lg-8 mb-5">
                  <h2 class="tit fw-500 fc-white ts-26 ts-md-32 mb-4" v-html="alleviate.tit" />
                  <p class="txt fw-400 fc-white ts-16 ts-md-18" v-html="alleviate.txt" />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="alleviate.btn" :theme="{type: 3, size: 'md', width: 174}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
import CompImg from '@/components/Img'
import CompCrypto from '@/components/Crypto'
import CompCard from '@/components/Card'
import BtnApp from '@/components/BtnApp'
export default {
  components: {
    Btn, CompImg, CompCrypto, CompCard, BtnApp
  },
  computed: {
    cc () {
      return this.$t('views.platform.dxmercato')
    },
    alleviate () {
      return this.$t('components.alleviate')
    },
    features () {
      const items = this.cc.s4.cards
      const splitPoint = Math.round(items.length / 2)
      return [items.splice(0, splitPoint), items]
    }
  },
  metaInfo () {
    const meta = this.$t('meta.platform.dxmercato')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
