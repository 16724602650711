<template>
  <section class="section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="article article--inner" v-if="articleSelected">
            <div class="article__content">
              <p class="txt fc-green ts-16 fw-500 mb-4">{{ articleSelected.date }}</p>
              <h1 class="tit ts-34 ts-md-42 fw-600 fc-white mb-4">{{ decode64(articleSelected.tit) }}</h1>
              <p class="txt ts-14 ts-md-18 fw-300 fc-white">{{ decode64(articleSelected.txt) }}</p>
            </div>
            <div class="article__img">
              <img :src="`/articles/${articleSelected.articleKey}/${decode64(articleSelected.imgSecondary)}`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-8 offset-2">
        <div class="article article--content" v-if="articleSelected" v-html="decode64(articleSelected.content)" />
       </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
  @import '../../assets/styles/components/articles';
</style>
<script>
export default {
  computed: {
    articleKey () {
      return this.$route.params.id
    },
    articlesList () {
      return this.$store.state.articlesData ? this.$store.state.articlesData[this.$i18n.locale] : null
    },
    articleSelected () {
      return this.articlesList ? this.articlesList[this.articleKey] : null
    }
  },
  methods: {
    decode64 (item) {
      return decodeURIComponent(escape(window.atob(item)))
    }
  },
  beforeCreate () {
    if (!this.$store.state.articlesData) this.$store.commit('getArticlesList')
  }
}
</script>
