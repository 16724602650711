<template>
  <div class="card card--t1">
    <div class="card__wrapper">
      <div class="card__head">
        <i class="card__icon">
          <img :src="`/img/icons/cards/ico-card-${data.key}.svg`" alt="icon" />
        </i>
      </div>
      <div class="card__body">
        <div class="mb-4">
          <h5 class="tit fw-600 fs-18 fc-dark ta-center mb-3" v-html="data.tit" />
          <p class="txt fc-dark ts-14 ta-center" v-html="data.txt" />
        </div>
        <router-link class="src fw-400 ts-14 fc-gray" :to="`${$i18n.locale}/${routes[data.src.key]}`">
          <span>{{ data.src.txt }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    },
    routes () {
      return this.$t('routes.internal')
    }
  }
}
</script>
