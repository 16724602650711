<template>
  <div class="view view--cryptocurrencies-supported">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7 mb-5 mb-md-0">
            <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 ta-center ta-md-left mb-3" v-html="cc.s1.tit" />
            <p class="txt fw-400 fc-gray ta-center ta-md-left" v-html="cc.s1.txt" />
          </div>
          <div class="col-12 col-md-5">
            <div class="img img--crypto-supported-s1">
              <img src="/img/views/img-cryptocurrencies-supported-s1.png" alt="supported-s1">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7 col-lg-8 mb-4 mb-md-0">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s2.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-4" v-html="cc.s2.tit[1]" />
            <p class="txt fc-dark ts-14 mb-4" v-html="cc.s2.txt[0]" />
            <p class="txt fc-dark ts-14" v-html="cc.s2.txt[1]" />
          </div>
          <div class="col-12 col-md-5 col-lg-4">
            <comp-crypto comp="supported" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12 col-xl-8">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s3.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32-32 mb-4" v-html="cc.s3.tit[1]" />
            <p class="txt fc-dark ts-14 mb-4" v-html="cc.s3.txt" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 mb-4 mb-md-0"
               v-for="(card, c) in cc.s3.cards" :key="`card-item-${c}`">
            <comp-card comp="t3" :data="card" />
          </div>
        </div>
        <div class="row mt-4 mt-md-5 pt-lg-3">
          <div class="col-12">
            <btn :data="cc.s3.btn" :theme="{type: 1, size: 'md', width: 'fc'}" class="mx-auto" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
import CompCard from '@/components/Card'
import CompCrypto from '@/components/Crypto'
export default {
  components: {
    Btn, CompCard, CompCrypto
  },
  computed: {
    cc () {
      return this.$t('views.cryptocurrencies.supported')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.cryptocurrencies.supported')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
