<template>
    <div class="row">
      <div class="col-12">
        <div class="video">
          <div class="video__item" v-for="(item, i) in videos" :key="`video-item-${i}`" @click.prevent="$parent.toggleVideo(i)">
            <div class="video__item-preview">
                  <img :src="`https://img.youtube.com/vi/${item.key}/0.jpg`" alt="img">
            </div>
            <p class="txt fw-600 ts-16 fc-white ta-center pb-2" v-html="item.title" />
            <p class="txt fc-white ta-center" v-html="item.date" />
          </div>
        </div>
      </div>
    </div>
  </template>
  <style lang="scss">
  @import '../assets/styles/components/videonews';
  </style>
<script>
export default {
  computed: {
    locale () {
      return this.$i18n.locale
    },
    videos () {
      return this.$parent.videoKeys[this.locale]
    }
  }
}
</script>
