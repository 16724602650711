<template>
  <div class="card card--t8">
    <div class="card__wrapper">
      <div class="card__head">
        <i class="card__icon">
          <img src="/img/icons/ui/ico-check-green.svg" alt="icon" />
        </i>
      </div>
      <div class="card__body">
        <h4 class="tit fw-400 ts-14 ts-md-16 fc-dark" v-html="data.tit"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    }
  }
}
</script>
