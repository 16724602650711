<template>
  <div class="faq-exp">
    <input class="faq-exp__check" :id="`faq-exp-item-${sid}`" type="checkbox" name="faq-radio-btn"/>
    <div class="faq-exp__wrapper">
      <label class="faq-exp__head" :for="`faq-exp-item-${sid}`" @click.prevent="onHeadClick()">
        <p class="txt fw-500 ts-18 fc-gray-5">{{ data.tit }}</p>
      </label>
      <div class="faq-exp__body">
        <div class="faq-exp__body-item" v-if="isInObj(data, 'txt')">
          <p class="txt ts-14 fc-dark" v-html="data.txt" />
        </div>
        <ul class="list list--policy" v-if="isInObj(data, 'list')">
          <li class="li" v-for="(item, i) in data.list" :key="`faq-list-${i}`">
            <p class="txt ts-14 fc-dark" v-html="item" />
          </li>
        </ul>
        <div class="faq-exp__body-item" v-if="isInObj(data, 'post')">
          <p class="txt ts-14 fc-dark" v-html="data.post" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import '../assets/styles/components/faq';
</style>
<script>
export default {
  props: {
    sid: {
      required: true
    },
    data: {
      required: true,
      type: Object
    }
  },
  methods: {
    onHeadClick () {
      const els = document.getElementsByName('faq-radio-btn')
      const targetId = `faq-exp-item-${this.sid}`
      const targetEl = document.getElementById(targetId)
      for (const el of els) if (el.id !== targetId) el.checked = false
      targetEl.checked = !targetEl.checked
    },
    isInObj (obj, key) {
      return key in obj
    }
  }
}
</script>
