<template>
  <div class="modal modal--video" :class="{'active': $store.state.isModalActive}">
    <div class="modal__close-area" @click.prevent="closeVideo()"></div>
    <div class="modal__wrapper">
      <div class="modal__close-btn" @click.prevent="closeVideo()"></div>
      <div class="modal__video">
        <youtube :video-id="null" ref="youtube"></youtube>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    key () {
      return this.$parent.data
    },
    player () {
      return this.$refs.youtube.player
    }
  },
  methods: {
    changeVideo () {
      this.player.loadVideoById(this.key).then(function (value) {
        value.mute()
        value.unMute()
      })
    },
    closeVideo () {
      this.player.pauseVideo(this.key)
      this.$store.state.isModalActive = false
    }
  },
  watch: {
    key: function () {
      this.changeVideo()
    }
  }
}
</script>
